import React from "react";
import { ReferralHeader } from "../components/referral/ReferralHeader";

type IReferrals = {
  children: JSX.Element | JSX.Element[];
};

const ReferralsLayout = ({ children }: IReferrals) => {
  return (
    <div className="text-white min-h-screen">
      <ReferralHeader />
      {children}
    </div>
  );
};

export default ReferralsLayout;
