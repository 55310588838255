import * as React from "react";
import { Dialog, Transition } from "@headlessui/react";
import qr from "../assets/qr.png";
import { ModalContext } from "../context/modalContext";

export const InstallDialog: React.FC = () => {
  const { modalOpen, setModalOpen } = React.useContext(ModalContext);

  return (
    <Transition.Root show={modalOpen} as={React.Fragment}>
      <Dialog
        as="div"
        className="fixed z-10 inset-0 overflow-y-auto"
        onClose={setModalOpen}
      >
        <div className="flex items-center justify-center min-h-screen text-center block p-0">
          <Transition.Child
            as={React.Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <Dialog.Overlay className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
          </Transition.Child>

          {/* This element is to trick the browser into centering the modal contents. */}
          <span
            className="hidden sm:inline-block sm:align-middle sm:h-screen"
            aria-hidden="true"
          >
            &#8203;
          </span>
          <Transition.Child
            as={React.Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
            enterTo="opacity-100 translate-y-0 sm:scale-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100 translate-y-0 sm:scale-100"
            leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
          >
            <div className="inline-block align-bottom bg-white rounded-3xl px-4 pt-5 pb-4 text-left overflow-hidden shadow-xl transform transition-all sm:my-8 sm:align-middle sm:max-w-md sm:w-full sm:p-6">
              <div className="block absolute top-0 right-0 mt-4 mr-4">
                <button
                  type="button"
                  className="text-gray-400 hover:text-gray-500 focus:outline-none bg-gray-200 p-3 rounded-full"
                  onClick={() => setModalOpen(false)}
                >
                  <span className="sr-only">Close</span>
                  <svg
                    className="h-2.5 w-2.5 text-black"
                    width="12"
                    height="12"
                    viewBox="0 0 12 12"
                    fill="black"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M11.705 1.53227C12.0563 1.18185 12.0563 0.613294 11.705 0.262833C11.3536 -0.0876284 10.7835 -0.0875912 10.4321 0.262833L6.00061 4.71523L1.53765 0.264329C1.18628 -0.0860949 0.616179 -0.0860949 0.264768 0.264329C-0.0866433 0.614753 -0.0866058 1.18331 0.264768 1.53377L4.72923 5.98317L0.26353 10.4677C-0.0878434 10.8182 -0.0878434 11.3867 0.26353 11.7372C0.614904 12.0876 1.185 12.0876 1.53641 11.7372L6.00061 7.25111L10.4636 11.702C10.8149 12.0524 11.385 12.0524 11.7365 11.702C12.0879 11.3516 12.0878 10.783 11.7365 10.4326L7.27199 5.98317L11.705 1.53227Z"
                      fill="black"
                    />
                  </svg>
                </button>
              </div>
              <div className="flex flex-col justify-center items-center text-center">
                <div className="my-6 text-center">
                  <Dialog.Title
                    as="h3"
                    className="sm:text-4.5xl leading-[45px] text-4xl sm:p-0 p-2 max-w-full text-black font-black italic"
                  >
                    Get Santa for iOS
                  </Dialog.Title>
                  <p className="text-lg m-3.5 leading-6 text-black">
                    Point your camera at the QR code to
                    <br />
                    download Santa
                  </p>
                  <img
                    className="mx-auto sm:w-min w-32"
                    src={qr}
                    alt="Santa on the AppStore"
                  />
                </div>
              </div>
            </div>
          </Transition.Child>
        </div>
      </Dialog>
    </Transition.Root>
  );
};
