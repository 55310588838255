import React from "react";

export interface Modal {
  modalOpen: boolean;
  setModalOpen: (open: boolean) => void;
}

export const ModalContext = React.createContext<Modal>({
  modalOpen: false,
  setModalOpen: (open: boolean) => {
    return open;
  },
});
