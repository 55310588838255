import React, { useState } from "react";
import { ModalContext } from "../context/modalContext";
import { InstallDialog } from "../components/InstallDialog";
import { ShadowHeader } from "../components/ShadowHeader";

type ILayout = {
  children: JSX.Element | JSX.Element[];
};

const Layout: React.FC<ILayout> = ({ children }) => {
  const [modalOpen, setModalOpen] = useState<boolean>(false);
  const modalContextValue = { modalOpen, setModalOpen };

  return (
    <main className="text-white min-h-screen bg-primary">
      <ShadowHeader />
      <ModalContext.Provider value={modalContextValue}>
        {children}
        <InstallDialog />
      </ModalContext.Provider>
    </main>
  );
};

export default Layout;
