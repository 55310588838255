import React from "react";
import MainLayout from "./main";
import ReferralsLayout from "./referrals";

type ILayout = {
  children: JSX.Element | JSX.Element[];
  pageContext: {
    layout: string;
  };
};

const Layout = ({ children, pageContext }: ILayout) => {
  if (pageContext.layout === "referral") {
    return <ReferralsLayout>{children}</ReferralsLayout>;
  } else {
    return <MainLayout>{children}</MainLayout>;
  }
};

export default Layout;
