import React from "react";
import { Helmet } from "react-helmet";
import { graphql, StaticQuery } from "gatsby";
import { ReferralHeaderData } from "../interface";

export const ReferralHeader = () => {
  return (
    <StaticQuery
      query={graphql`
        query SiteTitleReferralQuery {
          contentfulSiteMetadata {
            title
            siteUrl
          }
          contentfulReferral {
            pageMetaDataTitle
            pageMetaDataDescription {
              pageMetaDataDescription
            }
            pageMetaDataImage {
              file {
                url
              }
            }
          }
        }
      `}
      render={(headerData: ReferralHeaderData) => (
        <>
          <Helmet
            title={headerData.contentfulSiteMetadata.title}
            meta={[
              { name: "charSet", content: "utf-8" },
              {
                name: "Image",
                content:
                  headerData.contentfulReferral.pageMetaDataImage.file.url.replace(
                    "//",
                    "https://"
                  ),
              },
              {
                name: "facebook-domain-verification",
                content: process.env.GATSBY_FACEBOOK_DOMAIN_VERIFICATION,
              },
            ]}
          >
            <meta
              property="og:title"
              content={headerData.contentfulReferral.pageMetaDataTitle}
            />
            <meta property="og:type" content="website" />
            <meta
              property="og:url"
              content={headerData.contentfulSiteMetadata.siteUrl}
            />
            <meta
              property="og:description"
              content={
                headerData.contentfulReferral.pageMetaDataDescription
                  .pageMetaDataDescription
              }
            />
            <meta
              property="og:image"
              content={headerData.contentfulReferral.pageMetaDataImage.file.url.replace(
                "//",
                "https://"
              )}
            />
            <meta
              property="fb:app_id"
              content={process.env.GATSBY_FACEBOOK_APP_ID}
            />
          </Helmet>
        </>
      )}
    />
  );
};
