import React from "react";
import { graphql, StaticQuery } from "gatsby";
import { Helmet } from "react-helmet";
import { ShadowHeaderData } from "./interface";

export const ShadowHeader: React.FC = () => {
  return (
    <StaticQuery
      query={graphql`
        query SiteMetadataQuery {
          contentfulSiteMetadata {
            title
            siteUrl
            description {
              description
            }
            thumbnailImage {
              file {
                url
              }
            }
          }
        }
      `}
      render={(headerData: ShadowHeaderData) => (
        <>
          <Helmet
            title={headerData.contentfulSiteMetadata.title}
            meta={[
              { name: "charSet", content: "utf-8" },
              {
                name: "description",
                content:
                  headerData.contentfulSiteMetadata.description.description,
              },
              {
                name: "facebook-domain-verification",
                content: process.env.GATSBY_FACEBOOK_DOMAIN_VERIFICATION,
              },
              {
                property: "og:app_id",
                content: process.env.GATSBY_FACEBOOK_APP_ID,
              },
              {
                property: "og:title",
                content: headerData.contentfulSiteMetadata.title,
              },
              {
                property: "og:type",
                content: "website",
              },
              {
                property: "og:url",
                content: headerData.contentfulSiteMetadata.siteUrl,
              },
              {
                property: "og:description",
                content:
                  headerData.contentfulSiteMetadata.description.description,
              },
              {
                property: "og:image",
                content:
                  headerData.contentfulSiteMetadata.thumbnailImage.file.url.replace(
                    "//",
                    "https://"
                  ),
              },
              {
                property: "fb:app_id",
                content: process.env.GATSBY_FACEBOOK_APP_ID,
              },
            ]}
          />
        </>
      )}
    />
  );
};
